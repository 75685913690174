<template>
  <div class="content">
    <!-- 标题 -->
    <div class="article-type-container tochscroll">
      <div class="article-type">
        <md-tab-bar v-model="current"
                    :items="labellist"
                    @change="changeTab"
                    :maxLength="5"  :inkcolor="colorprimary" />
      </div>
    </div>
    <div class="tips btn"
         :style="`backgroundColor:${colorprimary}`"
         @click="toMyposter">我的海报</div>
    <div v-show="!isNull">
      <md-scroll-view class="scroll"
                      ref="scrollView"
                      auto-reflow
                      :scrolling-x="false"
                      @end-reached="loadMorechange">
        <div class="scroll">
          <div class="poster"
               v-for="(item, index) in imgLists"
               :key="index">
            <div class="imgTitle">
            <img class="img"
                 :src="ossurl + item.pichttp" />
              <img class="sxNew"
                   src="@/assets/abt/img/update.png"
                   v-show="item.zx == '1'" />
            </div>
            <div class="imgbtn"
                 :style="`border:1px solid ${colorprimary};color:${colorprimary}`"
                 @click="use(item.sno)">点击使用</div>
          </div>
        </div>
        <md-scroll-view-more slot="more"
                             :is-finished="listFinished" />
      </md-scroll-view>
    </div>
    <div class="nodata"
         v-show="isNull">
      <img class="kongimg"
           src="@/assets/image/null.png"
           alt="" />
    </div>
    <md-landscape v-model="showPic">
      <div class="showsctp"></div>
    </md-landscape>
  </div>
</template>
<script>
import {
  selectKJFL,
  selectPage,
  useHB,
  DoRecord
} from '@/api/abt/customerOperation/common'
import loadMorechange from '@/mixins/loadMorechange'
import { getStorage } from '@/lib/util'
import { Toast } from 'mand-mobile'
export default {
  mixins: [loadMorechange],
  data () {
    return {
      sctp: '',
      ossurl: '',
      pageNo: 1,
      size: 12,
      total: 0,
      totalPage: 0,
      loading: false,
      tabclass: '',
      stagcode: '',
      labellist: [
        {
          tagname: '最新',
          tagcode: 'new'
        }],
      // current: '',
      current: 'new',
      imgLists: [],
      showPic: false,
      height: '',
      listpageNo: 1,
      listsize: 9,
      listtotal: 0,
      listtotalPage: 0,
      loadflag: false,
      listFinished: false,
      isNull: true,
      user: '',
      colorprimary: ''
    }
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.user = getStorage('u_s', {})
    this.colorprimary = getStorage('theme', '')
    this.height = document.body.clientHeight
    this.getSelectList()
  },
  mounted () {
    document.querySelector('.scroll').style.height = `${this.height - 60}px`
  },
  methods: {
    getSelectList () {
      selectKJFL({ tagcode: '2' }).then(res => {
        this.labellist = this.labellist.concat(res.data.data)
        this.labellist.forEach((item, index) => {
          item.name = item.tagcode
          item.label = item.tagname
        })
        //  this.current = this.labellist[0].name
        //  this.stagcode = this.labellist[0].name
        this.getData()

        // this.labellist = res.data.data
        // for (var i = 0; i < this.labellist.length; i++) {
        //   this.labellist[i].value = this.labellist[i].tagcode
        //   this.labellist[i].label = this.labellist[i].tagname
        // }
      })
    },
    getData () {
      let data = {
        page: this.listpageNo,
        size: this.listsize,
        btagcode: '2',
        stagcode: this.stagcode,
        flag: '0'
      }
      selectPage(data).then(res => {
        this.imgLists =
          this.imgLists == []
            ? res.data.data.rows
            : this.imgLists.concat(res.data.data.rows)
        if (this.imgLists.length == 0) {
          this.isNull = true
        } else {
          this.isNull = false
        }
        this.listtotal = res.data.data.total
        this.listtotalPage = res.data.data.totalpage
        // console.log('--------------请求数据中--------------')
        // console.log('请求数据中总页数' + this.listtotalPage)
        // console.log('请求数据中当前页' + this.listpageNo)
        if (this.listtotalPage <= this.listpageNo) {
          this.listFinished = true
        } else {
          this.listFinished = false
          this.$refs.scrollView.finishLoadMore()
        }
        this.loadflag = true
        // console.log('请求数据中加载状态' + this.loadflag)
        // console.log('请求数据中是否停止加载' + this.listFinished)
      })
    },
    changeTab (item, index, prevIndex) {
      this.stagcode = item.name
      if (item.name == 'new') {
        this.stagcode = ''
      }
      this.listpageNo = 1
      this.imgLists = []
      this.listFinished = false
      this.loadflag = false
      this.$refs.scrollView.finishLoadMore()
      // if (!this.isNull) {
      //   this.$refs.scrollView.finishLoadMore()
      // }
      // this.isNull = false
      // console.log('--------------切换中--------------')
      // console.log('切换时总页数' + this.listtotalPage)
      // console.log('切换时当前页' + this.listpageNo)
      // console.log('切换时加载状态' + this.loadflag)
      // console.log('切换时是否停止加载' + this.listFinished)
      this.getData()
    },
    use (val) {
      Toast.loading('生成中...')
      $(document).ready(function () {
        $('.showsctp').empty()
      })
      this.sctp = ''
      useHB({ sno: val, flag: '2', rytype: this.user.rytype }).then(res => {
        this.sctp = res.data.data.url
        let aaa = "<img src='" + this.ossurl + this.sctp + "' alt='' />"
        $(document).ready(function () {
          $('.showsctp').append(aaa)
        })
        this.showPic = true
        DoRecord({
          userid: this.user.userid,
          suserid: this.user.userid,
          empno: this.user.empno,
          rytype: this.user.rytype,
          stagcode: this.stagcode,
          otype: '3',
          sno: res.data.data.sno,
          osno: res.data.data.osno,
          btagcode: '2'
        }).then(res => { })
        Toast.hide()
      })
    },
    toMyposter () {
      this.$router.push('/myposter')
    }
  }
}
</script>
<style lang="stylus" scoped>
.scroll {
  overflow-y: auto;
}

.tabcss {
  color: rgb(1, 84, 164) !important;
  border-bottom: 1px solid rgb(1, 84, 164);
}

.article-type-container {
  position: relative;
  height: 130px;

  .article-type {
    background: #fcfcfc;
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    height: 130px;
    // margin-right:130px;
    border-bottom: 1px solid #f0f0f0;

    // .type-lists
    // font-size:0.4rem;
    // display: flex;
    // width: auto;
    // white-space: nowrap;
    // li
    // padding: 40px 40px 20px;
    // font-size: 0.4rem;
    // background-color: white;
    // font-weight: 900;
    .fdj {
      width: 130px;
      position: absolute;
      top: 0;
      right: 0;
      height: 130px;
      background-image: url('../../../../assets/abt/img/searchwz.png');
      background-repeat: no-repeat;
      background-size: auto 40%;
      background-position: center;
      background-color: white;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}

.poster {
  float: left;
  width: 33%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  .imgTitle {
    position: relative;
    .img {
      position: relative;
      width: 100%;
      height: 500px;
    }

    .sxNew {
      position: absolute;
      right: 0;
      width: 150px;
      height: auto;
      z-index: 999;
    }
  }

  .imgbtn {
    width: 200px;
    margin: 10px auto;
    padding: 10px 30px;
    //color: color-primary;
    //border: 1px solid color-primary;
    border-radius: 10px;
    font-size: 30px;
  }
}

.btn {
  z-index: 999;
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 300px;
    height: auto;
  }
}

/deep/.md-landscape-content {
  width: 740px;
}

/deep/ .scroll-view-container {
  margin-top: 0.1rem;
}

/deep/.md-tab-bar-item {
  font-size: 40px;
  font-weight: 600;
  color: #000;
}
</style>
